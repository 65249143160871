.image {
    box-shadow: 0 20px 40px rgba(0,0,0,0.4);
  }


.imageL {
    width: 80px;
}

.card-content {
    text-align: 'center';
}

@media screen and (max-width: 1150px) {
    .imageL {
        display: none;
    }
}