#logo {
    min-height: 100px;
    left: 0;
    margin-left: 10px;
    max-width: 300px;
    line-height: 2em;
    
}

.nav {
    display: flex;
    flex-direction: row;
}

#navbar {
    margin-left: auto;
    background-color: white;
    z-index: 2;
}

#navbar.stickyBar {
    margin-left: auto;
    background-color: white;
    border-bottom-left-radius: 10px;
    box-shadow: -1px 5px 15px 5px rgba(0,0,0,0.56);
    z-index: 2;
}

.topnav .icon {
    display: none;
}
.topnav.stickyBar .icon {
    display: none;
}

#img_logo {
    height: 120px;
    cursor: pointer;
}

.stickyBar {
    position: fixed;
    top: 0;
    right: 0;
  }


.menu_option {
    display: block;
    margin: auto;
    letter-spacing: 0.01em;
    display: inline-block;
    padding: 15px 20px;
    margin-top:40px;
    position: relative;
    color: #f7931e !important;
}

.stickyBar .menu_option {
    display: block;
    display: inline-block;
    margin: auto;
    letter-spacing: 0.01em;
    position: relative;
    padding: 15px 20px;
    margin-top:0
}

.menu_option:hover {
    cursor: pointer;
}

.menu_option:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #c6312b;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.menu_option:hover:after { 
    width: 100%; 
    left: 0; 
}


@media screen and (max-width: 1150px) {
    .topnav div {display: none;}
    .topnav .icon {
      float: right;
      display: block;
    }
    .topnav.stickyBar div {display: none;}
    .topnav.stickyBar .icon {
      float: right;
      display: block;
    }
  }
@media screen and (max-width: 1150px) {
    .menu_option {
        margin-top:0;
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .topnav.responsive div {
        float: none;
        display: block;
        text-align: left;
    }
    .topnav.responsive.stickyBar div {
        float: none;
        display: block;
        text-align: left;
    }
}

