.sms {
 display: none !important;
}


.imgCards img {
    max-width: 450px;
}

@media screen and (max-width: 1150px) {
    .lgs {
        display: none !important;
    }
    .sms {
        display: flex !important;
       }

    .imgCards {
        display: none !important;
    }
}