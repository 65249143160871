html {
  /*background: rgb(0,154,8);
  background: linear-gradient(0deg, #009a08ea 0%, rgba(255,255,255,1) 67%);*/
  color: #f7931e !important;
  font-family: "Open Sans", Arial, Tahoma, sans-serif;
  font-size: 17px;
  line-height: 25px;
  font-weight: bold !important;
  background-color: white;
}

a {
  text-decoration: none !important;
}